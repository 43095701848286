/* Navbar styles */
.navbar {
  padding: 1rem;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-menu {
  display: flex;
  gap: 1rem;
}

.navbar-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
}

.navbar-link svg {
  font-size: 1.2rem;
}

.navbar-menu {
  display: flex;
  gap: 2rem;
}

.navbar-link:hover {
  color: white;
}

.navbar-toggle {
  display: none;
}

.navbar-responsive {
  display: none;
}

@media (max-width: 768px) {
  .navbar-menu {
      display: none;
  }

  .navbar-toggle {
      display: block;
      font-size: 1.5rem;
      cursor: pointer;
  }

  .navbar-responsive {
      display: block;
      position: absolute;
      top: 60px;
      left: 0;
      width: 100%;
      text-align: center;
  }

  .navbar-responsive ul {
      flex-direction: column;
      gap: 1rem;
  }

  .navbar-responsive a {
      display: block;
      padding: 1rem;
  }

  .navbar-responsive a:hover {
      color: white;
  }
}
