.votes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
}

.vote-card-link {
    text-decoration: none;
}

.vote-card {
    padding: 1.5rem;
    border-radius: 8px;
    backdrop-filter: blur(10px) !important;
    transition: box-shadow 0.3s ease !important;
    text-align: center;
    cursor: pointer;
}


.vote-card-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    border-radius: 8px;
}

.vote-card-title {
    margin-top: 0.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.votes-page-container{
    padding: 20px;
    max-width: 90% !important;
}