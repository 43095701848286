body {
  height: auto !important;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.server-logo {
  animation: float 3s ease-in-out infinite;
  border-radius: 50%;
}

.main-page-container {
  margin: 0 auto;
  padding: 20px;
  border-radius: 12px;
  max-width: 90%;
  margin-top: 20px !important;
  margin-bottom: 120px !important;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

button {
  transition: transform 0.2s ease;
}

button:hover {
  transform: scale(1.05);
}

.announcements-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.announcement-card {
  margin-top: 16px;
  padding: 20px;
  border-radius: 8px;
  background-color: var(--backgroundColor);
  custom-fade: "%%__NONCE__%%";
  backdrop-filter: var(--backdropFilter);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2) !important;
  transition: box-shadow 0.3s ease !important;
}

.post-date {
  margin-top: 2px !important;
  margin-bottom: 16px !important;

}

.announcement-card:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
}

.read-more-button {
  margin-top: 20px !important;
}

.status-container {
  display: flex;
  justify-content: center;
}

.status-card {
  padding: 0px;
  border-radius: 8px;
  width: 100%;
  height: auto;
  background-color: var(--backgroundColor);
  backdrop-filter: var(--backdropFilter);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2) !important;
  transition: box-shadow 0.3s ease !important;
}

.status-card:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3) !important;
}
