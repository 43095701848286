/* Ensure that the main container takes up the full height */


#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.app-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.main-content {
    flex-grow: 1; /* Ensures the footer is pushed to the bottom */
    padding-top: 100px !important;
}

footer {
    width: 100%;
    padding: 1rem;
    background-color: rgba(18, 18, 18, 0.8);
    color: #ffffff;
    text-align: center;
    font-size: 1rem;
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
