.staff-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
}

.staff-page-container{
    max-width: 90% !important;
}

.staff-card-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
}

.staff-card-name {
    margin-top: 1rem;
}

.staff-card-role {
    margin-top: 0.5rem;
}
