@tailwind base;
@tailwind components;
@tailwind utilities;

/* Apply theme-related variables */
:root {
  --primary-color: #90caf9; /* Default primary */
  --secondary-color: #f48fb1; /* Default secondary */
  --bg-color: #121212;
  --paper-bg-color: #1d1d1d;
  --text-color: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--text-color);
  background-image: url('./assets/underwater.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.markdown > * {
  all: revert;
}

table {
  border-spacing: 0 !important;
  border-collapse: collapse !important;
  border-color: inherit !important;
  display: block !important;
  width: max-content !important;
  max-width: 100% !important;
  overflow: auto !important;
}

tbody, td, tfoot, th, thead, tr {
  border-color: inherit !important;
  padding: 5px 10px !important;
  border-style: solid !important;
  border-width: 2px !important;
}