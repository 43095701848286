.rules-page-container {
    padding: 20px;
    max-width: 90% !important;
    border-radius: 12px;
    box-shadow: 0 4px 10px var(--boxShadowLight);
    background: var(--containerBackground);
    backdrop-filter: var(--backdropFilter);
}

.rules-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.rule-card {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 8px;
    background: var(--cardBackground) !important;
    box-shadow: 0 4px 16px var(--boxShadowMedium) !important;
    transition: box-shadow 0.3s ease !important;
    cursor: pointer;
}

.rule-card:hover {
    box-shadow: 0 8px 24px var(--boxShadowHover) !important;
}

.icon-container {
    margin-right: 1rem;
}

.rule-text {
    flex-grow: 1;
}
