.markdown-container {
    max-width: 100%;
}

.markdown-image {
    height: auto;
    margin: 10px;
}

.markdown h1, .markdown h2, .markdown h3 {
    margin-bottom: 1rem;
}

.markdown ul {
    margin-left: 20px;
    list-style-type: disc;
}

.markdown table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
}

.markdown table, .markdown th, .markdown td {
    padding: 8px;
    text-align: left;
}

.post-container{
    max-width: 100% !important;
}