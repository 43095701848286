.footer {
    width: 100%;
    padding: 1rem;
    text-align: center;
    font-size: 1rem;
    position: relative;
    margin-top: 2rem;
}

.footer a:hover {
    text-decoration: underline;
}
